export class Contact {
  id: number
  name: string
  email: string
  phone: string
  customer_id: number
  is_active: boolean
  constructor() {
  }

  static buildOne(obj: any): Contact {
    let target: Contact = Object.assign(new Contact(), obj)
    return target
  }

  static buildMany(objs: any[]): Contact[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Contact.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}


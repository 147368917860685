import {Injectable} from '@angular/core'
import {Action, NgxsOnInit, NgxsSimpleChange, Select, Selector, State, StateContext, Store} from '@ngxs/store'
import {Observable} from 'rxjs';
import {UserState} from 'src/app/state-man/state/user.state';
import {User} from 'src/app/state-man/models/user.model';
import {TicketActions} from '../actions/ticket.actions';
import {Ticket} from '../models/ticket.model';
import {TicketService} from 'src/app/services/ticket.service';
import {append, patch, updateItem} from '@ngxs/store/operators';
import {ConnectionStatusEnum, NetworkService} from 'src/app/services/network.service';
import {OfflineService} from 'src/app/services/offline.service';
import {DateTime} from 'luxon';
import {StorageService} from 'src/app/services/storage.service';

export class TicketStateModel {
    is_refreshing: boolean;
    pending_count: number;
    last_update: string;
    tickets: Ticket[];
}

@State<TicketStateModel>({
    name: 'ticket', defaults: {
        is_refreshing: true, pending_count: 0, last_update: '', tickets: []
    }
}) @Injectable()
export class TicketState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>;

    constructor(private ticketService: TicketService, private networkService: NetworkService, private offline: OfflineService, private store: Store, private storageService: StorageService) {
    };

    async ngxsOnInit(ctx: StateContext<TicketStateModel>) {
        this.user$.subscribe(async (user: User) => {
            if (user.is_logged_in) {
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {
                        await this.reloadStateFromStorage();
                        this.store.dispatch(new TicketActions.IncrementPendingCount());
                    }
                });
            }
        });
    };

    async ngxsOnChanges(change: NgxsSimpleChange) {
        if (this.storageService.is_initialized) {
            const state_model: TicketStateModel = change.currentValue;
            await this.storageService.set('tickets', JSON.stringify(state_model));
        }
        if (this.networkService.currentStatus === ConnectionStatusEnum.Online) {
            if (change.previousValue && change.previousValue.pending_count < change.currentValue.pending_count) {
                const state_model: TicketStateModel = change.currentValue;
                await this.offline.processPendingTickets(state_model.tickets);
            }
        }
    };

    async reloadStateFromStorage() {
        const json: string = await this.storageService.get('tickets');
        if (json) {
            const state_model: TicketStateModel = JSON.parse(json);
            if (state_model) {
                this.store.dispatch(new TicketActions.Init(state_model));
                return;
            }
        }
        this.store.dispatch(new TicketActions.Refresh());
    };

    async reloadStateFromApi(): Promise<boolean> {
        const objs = await this.ticketService.getMine();
        if (objs != null) {
            const many = Ticket.buildMany(objs);
            this.store.dispatch(new TicketActions.Merge(many));
            return true;
        }
        return false;
    };

    @Selector()
    static getLastUpdate(state: TicketStateModel) {
        return state.last_update;
    };

    @Selector()
    static getIsRefreshing(state: TicketStateModel) {
        return state.is_refreshing;
    };

    @Selector()
    static getState(state: TicketStateModel) {
        return state;
    };

    @Selector()
    static getAll(state: TicketStateModel) {
        return state.tickets;
    };

    @Selector()
    static getOne(state: TicketStateModel) {
        return (id: string) => {
            return state.tickets.filter(s => s.id === id);
        };
    };

    @Action(TicketActions.Refresh)
    async refresh({patchState}: StateContext<TicketStateModel>, {}: TicketActions.Refresh) {
        patchState({is_refreshing: true});
        if (!await this.reloadStateFromApi()) {
            patchState({is_refreshing: false});
        }
    };

    @Action(TicketActions.IncrementPendingCount) incrementPendingCount({
                                                                           patchState,
                                                                           getState
                                                                       }: StateContext<TicketStateModel>, {}: TicketActions.IncrementPendingCount) {
        const state = getState();
        patchState({
            pending_count: state.pending_count + 1
        });
    }

    @Action(TicketActions.Init) init({
                                         setState,
                                         getState
                                     }: StateContext<TicketStateModel>, {payload}: TicketActions.Init) {
        const state = getState();
        payload.is_refreshing = state.is_refreshing;
        setState(payload);
        this.store.dispatch(new TicketActions.Refresh());
    };

    @Action(TicketActions.Set) set({setState, getState}: StateContext<TicketStateModel>, {payload}: TicketActions.Set) {
        const state = getState();
        setState({
            is_refreshing: state.is_refreshing,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            tickets: payload
        });
    };

    @Action(TicketActions.Merge) merge({
                                           getState,
                                           setState
                                       }: StateContext<TicketStateModel>, {payload}: TicketActions.Merge) {
        const state = getState();
        const oldTickets = [...state.tickets];
        let newTickets = [...payload];

        for (const ticket of oldTickets) {
            let newTicket = payload.find(f => f.id === ticket.id);
            if (!newTicket) {
                if (ticket.is_pending_save || ticket.is_pending_delete) {
                    newTickets.push(ticket);
                }
            } else {
                if (ticket.is_pending_save || ticket.is_pending_delete) {
                    newTickets = newTickets.filter((obj) => {
                        return obj !== newTicket;
                    });
                    newTickets.push(ticket);
                }
            }
        }

        setState({
            is_refreshing: false,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            tickets: newTickets
        });
    };

    @Action(TicketActions.AddOne) addOne({
                                             getState,
                                             patchState
                                         }: StateContext<TicketStateModel>, {payload}: TicketActions.AddOne) {
        const state = getState();
        patchState({
            tickets: [...state.tickets, payload]
        });
        new TicketActions.IncrementPendingCount();
    };

    @Action(TicketActions.AddPhotoToTicket) addPhotoToTicket({ setState }: StateContext<TicketStateModel>, {payload}: TicketActions.AddPhotoToTicket) {
        setState(
            patch<TicketStateModel>({
                tickets: updateItem((item: Ticket) => item.id === payload.ticket_id, patch({photos: append([payload.photo])}))
            })
        );
    };

    @Action(TicketActions.UpdateOne) updateOne({setState}: StateContext<TicketStateModel>, {payload}: TicketActions.UpdateOne) {
        setState(patch<TicketStateModel>({
            tickets: updateItem((item: Ticket) => item.id === payload.id, patch<Ticket>(payload)),
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")
        }));
    };

    @Action(TicketActions.DeleteOne) deleteOne({setState}: StateContext<TicketStateModel>, {payload}: TicketActions.DeleteOne) {
        setState(patch<TicketStateModel>({
            tickets: updateItem((item: Ticket) => item.id === payload.id, patch<Ticket>(payload)),
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")
        }));
    };
}

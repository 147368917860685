import {Photo} from './photo.model'

export class TicketPhoto extends Photo {
    // Model
    ticket_id: string;
    attachment_id: string;

    public async setViewModelFields() {
        if (this.photo_data && this.photo_type) {
            this.base64 = 'data:' + this.photo_type + ';base64,' + this.photo_data
            this.base64_sz = this.base64
        }
    }

    constructor() {
        super()
    }

    static override async buildOne(obj: any): Promise<TicketPhoto> {
        let target: TicketPhoto = Object.assign(new TicketPhoto(), obj)
        await target.setViewModelFields()
        return target
    }

    static override async buildMany(objs: any): Promise<TicketPhoto[]> {
        const lobjs = []
        for (const i in objs) {
            if (objs.hasOwnProperty(i)) {
                lobjs.push(await TicketPhoto.buildOne(objs[i]))
            }
        }
        return lobjs
    }
}


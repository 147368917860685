export class SubCategory {
    id: number;
    name: string;
    category_id: number;

    constructor() {
    }

    static buildOne(obj: any): SubCategory {
        let target: SubCategory = Object.assign(new SubCategory(), obj);
        return target;
    }

    static buildMany(objs: any[]): SubCategory[] {
        const lobjs = [];
        for (const i in objs) {
            if (objs.hasOwnProperty(i)) {
                lobjs.push(SubCategory.buildOne(objs[i]));
            }
        }
        return lobjs;
    };
}


import {Ticket, TicketAndPhoto} from "../models/ticket.model";
import {TicketStateModel} from "../state/ticket.state";

export namespace TicketActions {
    export class Refresh {
        static readonly type = '[API-TICKET] Refresh'

        constructor() {
        }
    }

    export class IncrementPendingCount {
        static readonly type = '[Network Service-Ticket] Increment Pending Count'

        constructor() {
        }
    }

    export class Init {
        static readonly type = '[Storage-Ticket] Init'

        constructor(public payload: TicketStateModel) {
        }
    }

    export class Set {
        static readonly type = '[API-Ticket] Set'

        constructor(public payload: Ticket[]) {
        }
    }

    export class Merge {
        static readonly type = '[API-Ticket] Merge'

        constructor(public payload: Ticket[]) {
        }
    }

    export class AddOne {
        static readonly type = '[UI-Ticket] Add One'

        constructor(public payload: Ticket) {
        }
    }

    export class UpdateOne {
        static readonly type = '[UI-Ticket] Update One'

        constructor(public payload: Ticket) {
        }
    }

    export class DeleteOne {
        static readonly type = '[UI-Ticket] Delete One'

        constructor(public payload: Ticket) {
        }
    }

    export class AddPhotoToTicket {
        static readonly type = '[UI-Ticket] Add Photo to Ticket'

        constructor(public payload: TicketAndPhoto) {
        }
    }

    export class ApiAddedPhotoToTicket {
        static readonly type = '[API-Ticket] Added Photo to Ticket'

        constructor(public payload: TicketAndPhoto) {
        }
    }

    export class ApiStartUploading {
        static readonly type = '[API-Ticket] Start Uploading Photo'

        constructor(public payload: TicketAndPhoto) {
        }
    }

    export class ApiStopUploading {
        static readonly type = '[API-Ticket] Stop Uploading Photo'

        constructor(public payload: TicketAndPhoto) {
        }
    }
}

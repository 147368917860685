import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {SubCategory} from '../state-man/models/sub-category.model';
import {UiService} from './ui.service';
import {LoggerService} from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class SubCategoryService {

    constructor(private apiService: ApiService, private uiService: UiService) {
    }

    public async getAll(): Promise<SubCategory[] | null> {
        try {
            const ret = await this.apiService.apiReturnsAny('get', 'it/sub_category/');
            return (ret && Array.isArray(ret)) ? SubCategory.buildMany(ret) : null;
        } catch (error) {
            LoggerService.log(error + 'it/sub_category/');
            await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.');
            return null;
        }
    };

}

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Ticket} from '../state-man/models/ticket.model';
import {LoggerService} from './logger.service';
import {UiService} from './ui.service';
import {PhotoService} from "./photo.service";
import {TicketPhoto} from "../state-man/models/ticket-photo.model";

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    constructor(private apiService: ApiService, private uiService: UiService) {
    }

    public async getMine(): Promise<Ticket[] | null> {
        try {
            const ret = await this.apiService.apiReturnsAny('get', 'it/ticket/user/');
            const tickets = (ret && Array.isArray(ret)) ? Ticket.buildMany(ret) : null;

            if (!tickets) return null;
            return tickets;
        } catch (error) {
            LoggerService.log(error + 'ticket/mine');
            await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.');
            return null;
        }
    };

    public async addTicket(ticket: Ticket): Promise<Ticket | null> {
        try {
            return await this.apiService.apiReturnsAny('post', 'it/ticket/', ticket, 'application/json');
        } catch (error) {
            LoggerService.log(error + 'post ticket/');
            await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.');
            return null;
        }
    };

    public async deleteTicket(ticket: Ticket): Promise<any> {
        try {
            return await this.apiService.apiReturnsAny('delete', 'it/ticket/' + ticket.id, ticket, 'application/json');
        } catch (error) {
            LoggerService.log(error + 'delete it/ticket/');
            await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.');
            return null;
        }
    };

    public async addPhoto(ticket: Ticket, photo: TicketPhoto): Promise<TicketPhoto | null> {
        if (photo.uuid && ticket.id) {
            try {
                const body = new FormData();
                const base64Image = photo.base64_sz.split(';base64,').pop();
                const blob = PhotoService.b64toBlob(base64Image, photo.file_type);
                body.append('media_file', blob, 'media_file');
                return await this.apiService.apiReturnsAny('post', 'it/ticket/attachment/' + ticket.id, body, 'multipart/form-data');
            } catch (error) {
                LoggerService.log(error + '/it/ticket/attachment/');
                await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.');
                return null;
            }
        } else return null;
    };

    public async deletePhoto(photo: TicketPhoto): Promise<any | null> {
        try {
            return await this.apiService.apiReturnsAny('delete', 'it/ticket/attachment/' + photo.id);
        } catch (error) {
            LoggerService.log(error + 'delete /it/ticket/attachment/');
            await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.');
            return null;
        }
    };
}

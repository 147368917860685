import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Category} from '../state-man/models/category.model';
import {UiService} from './ui.service';
import {LoggerService} from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    constructor(private apiService: ApiService, private uiService: UiService) {
    }

    public async getAll(): Promise<Category[] | null> {
        try {
            const ret = await this.apiService.apiReturnsAny('get', 'it/category/');
            return (ret && Array.isArray(ret)) ? Category.buildMany(ret) : null;
        } catch (error) {
            LoggerService.log(error + 'it/category/');
            await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.');
            return null;
        }
    };

}

import { Vehicle } from "./vehicle.model"

export class Technician {
  id: number
  name: string
  time_on_job: string
  vehicle_id: number
  vehicle_name: string
  is_active: boolean
  constructor() {
  }

  static buildOne(obj: any): Technician {
    let target: Technician = Object.assign(new Technician(), obj)
    return target
  }

  static buildMany(objs: any[]): Technician[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Technician.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}


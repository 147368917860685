export class Category {
    id: number;
    name: string;

    constructor() {
    }

    static buildOne(obj: any): Category {
        let target: Category = Object.assign(new Category(), obj);
        return target;
    };

    static buildMany(objs: any[]): Category[] {
        const lobjs = [];
        for (const i in objs) {
            if (objs.hasOwnProperty(i)) {
                lobjs.push(Category.buildOne(objs[i]));
            }
        }
        return lobjs;
    };
}


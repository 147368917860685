import {SubCategory} from "../models/sub-category.model";
import {SubCategoryStateModel} from "../state/sub-category.state";

export namespace SubCategoryActions {
    export class Refresh {
        static readonly type = '[API-SubCategory] Refresh'

        constructor() {
        }
    }

    export class Init {
        static readonly type = '[Storage-SubCategory] Init'

        constructor(public payload: SubCategoryStateModel) {
        }
    }

    export class Set {
        static readonly type = '[API-SubCategory] Set'

        constructor(public payload: SubCategory[]) {
        }
    }
}

import {Category} from "../models/category.model";
import {CategoryStateModel} from "../state/category.state";

export namespace CategoryActions {
    export class Refresh {
        static readonly type = '[API-Category] Refresh'

        constructor() {
        }
    }

    export class Init {
        static readonly type = '[Storage-Category] Init'

        constructor(public payload: CategoryStateModel) {
        }
    }

    export class Set {
        static readonly type = '[API-Category] Set'

        constructor(public payload: Category[]) {
        }
    }
}

import {TicketPhoto} from "./ticket-photo.model";

export class TicketAndPhoto {
    ticket_id: string;
    photo: TicketPhoto;
}

export class Ticket {
    id: string;
    number: string;
    description: string;
    ticket_status: string;
    ticket_urgency_id: number;
    category_id: number;
    category: string;
    sub_category_id: number;
    sub_category: string;
    created_by: string;
    created_at: string;
    updated_at: string;
    company_id: number;
    company_name: string;
    author: string;
    position: string;
    is_pending_save: boolean;
    is_pending_delete: boolean;
    attachments: TicketPhoto[] = [];
    photos: TicketPhoto[] = [];
    deleted_photos: TicketAndPhoto[] = [];

    constructor() {
    }

    public async setViewModelFields() {
        if (!this.number) {
            this.number = '';
        }
        if (!this.description) {
            this.description = '';
        }
        if (this.ticket_urgency_id === undefined) {
            this.ticket_urgency_id = 0;
        }
        if (this.category_id === undefined) {
            this.category_id = 0;
        }
        if (this.sub_category_id === undefined) {
            this.sub_category_id = 0;
        }
        if (this.is_pending_save === undefined) {
            this.is_pending_save = false;
        }
        if (this.is_pending_delete === undefined) {
            this.is_pending_delete = false;
        }
    };

    static buildOne(obj: any): Ticket {
        let target: Ticket = Object.assign(new Ticket(), obj);
        target.setViewModelFields();
        return target
    };

    static buildMany(objs: any[]): Ticket[] {
        const lobjs = [];
        for (const i in objs) {
            if (objs.hasOwnProperty(i)) {
                lobjs.push(Ticket.buildOne(objs[i]));
            }
        }
        return lobjs;
    };
}


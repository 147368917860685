import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, Store } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { DsrActions } from '../actions/dsr.actions';
import { Dsr } from '../models/dsr.model';
import { DsrService } from 'src/app/services/dsr.service';
import { compose, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ConnectionStatusEnum, NetworkService } from 'src/app/services/network.service';
import { OfflineService } from 'src/app/services/offline.service';
import { DateTime } from 'luxon';
import { StorageService } from 'src/app/services/storage.service';
import { ServiceEntry } from '../models/service-entry.model';
import { LoggerService } from 'src/app/services/logger.service';

export class DsrStateModel {
    is_refreshing: boolean
    pending_count: number
    last_update: string
    dsrs: Dsr[]
}

@State<DsrStateModel>({
    name: 'dsr',
    defaults: {
        is_refreshing: true,
        pending_count: 0,
        last_update: '',
        dsrs: []
    }
})
@Injectable()
export class DsrState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private dsrService: DsrService,        
        private networkService: NetworkService,
        private offline: OfflineService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<DsrStateModel>) {   
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                 
                        await this.reloadStateFromStorage()   
                        this.store.dispatch(new DsrActions.IncrementPendingCount())
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {            
        if (this.storageService.is_initialized) {      
            const state_model: DsrStateModel = change.currentValue      
            this.storageService.set('dsrs', JSON.stringify(state_model))    
        }      
        if (this.networkService.currentStatus === ConnectionStatusEnum.Online) {            
            if (change.previousValue && change.previousValue.pending_count < change.currentValue.pending_count) {                
                const state_model: DsrStateModel = change.currentValue
                await this.offline.processPendingDsrs(state_model.dsrs)                    
            } 
        }   
    }
    
    async reloadStateFromStorage() {        
        const json: string = await this.storageService.get('dsrs');        
        if (json) {
            const state_model: DsrStateModel = JSON.parse(json)            
            if (state_model) {                
                this.store.dispatch(new DsrActions.Init(state_model));
                return
            } 
        }
        this.store.dispatch(new DsrActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.dsrService.getMine()    
        if (objs != null) {
          const many = Dsr.buildMany(objs)                     
          this.store.dispatch(new DsrActions.Merge(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getLastUpdate(state: DsrStateModel) {
        return state.last_update
    }

    @Selector()
    static getIsRefreshing(state: DsrStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getState(state: DsrStateModel) {
        return state
    }

    @Selector()
    static getAll(state: DsrStateModel) {
        return state.dsrs
    }

    @Selector()
    static getOne(state: DsrStateModel) {
        return (id: number) => {
            return state.dsrs.filter(s => s.id === id)
        }
    }

    @Action(DsrActions.Refresh)
    async refresh({patchState}: StateContext<DsrStateModel>, {  }:DsrActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }

    @Action(DsrActions.IncrementPendingCount)
    incrementPendingCount({patchState, getState}: StateContext<DsrStateModel>, {  }:DsrActions.IncrementPendingCount) {     
        const state = getState()
        patchState({
            pending_count: state.pending_count + 1
        })
    }
    
    @Action(DsrActions.Init)
    init({setState, getState}: StateContext<DsrStateModel>, { payload }:DsrActions.Init) {                  
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new DsrActions.Refresh())
    }
    
    @Action(DsrActions.Set)
    set({setState, getState}: StateContext<DsrStateModel>, { payload }:DsrActions.Set) {      
        const state = getState()        
        setState({   
            is_refreshing: state.is_refreshing, 
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),         
            dsrs: payload
        })
    }

    @Action(DsrActions.Merge)
    merge({getState, setState}: StateContext<DsrStateModel>, { payload }:DsrActions.Merge) {   
        // This is to merge data from the API provided in payload, into the app state.
        // Basically, if we have server data, we trust that, so we want to replace the 
        // current app state with the payload, except:
        // 1. Any objects pending upload or delete need to be merged in, even if it no longer exists.
        // 2. Same goes for any with a service_entry that is pending approval upload.
        const state = getState()
        const oldDsrs = [...state.dsrs]
        let newDsrs = [...payload]
        
        /*
        // For each dsr in state now...
        for (const dsr of oldDsrs) {   
            if (!dsr.service_entries) {                
                LoggerService.log('no service entries')
            }
            // If dsr id is not in the list from the server  
            let newDsr = payload.find(f => f.id === dsr.id)  
            if (dsr && dsr.service_entries && Array.isArray(dsr.service_entries) && dsr.service_entries.length > 0) {             
                if (!newDsr) {
                    // If there are any pending uploads, keep the entire object in state
                    if (dsr.id <= 0) {
                        newDsrs.push(dsr)
                    }
                    if (dsr.service_entries.find(f => f.is_approval_pending === true)) {
                        newDsrs.push(dsr)
                    }
                } else {  // Update list with all but this one, and then add this one from payload since it is now new but exists and needs to be updated.
                    if (dsr.service_entries.find(f => f.is_approval_pending === true)) {                        
                        newDsrs = newDsrs.filter((obj) => {return obj !== newDsr});    
                        newDsrs.push(dsr)           
                    }
                }
            }
            else {
                LoggerService.log('Why are there no service entries?')
            }
        }        
        */

        // For each wor in state now...
        for (const dsr of oldDsrs) {   
            if (!dsr.service_entries) {                
                LoggerService.log('no service entries')
            }
            // If wor id is not in the list from the server       
            let newDsr = payload.find(f => f.id === dsr.id)   
            if (!newDsr) {                
                // If there are any pending save/delete, keep the entire object in state, else do not since no longer on server                
                if (dsr.is_pending_save || dsr.is_pending_delete || (dsr.service_entries && dsr.service_entries.find(f => f.is_approval_pending === true))) {                 
                    newDsrs.push(dsr)
                }
            } else {                
                // Object in state is in the payload from the server. 
                // 1. If anything is pending, we need to preserve the one in state, first removing the one that is from the server
                // 1. Else we leave the one from the server to set in the new state
                if (dsr.is_pending_save || dsr.is_pending_delete || (dsr.service_entries && dsr.service_entries.find(f => f.is_approval_pending === true))) {
                    // Remove one that is from the server
                    newDsrs = newDsrs.filter((obj) => {return obj !== newDsr});                        
                    // Add the one from state back in
                    newDsrs.push(dsr)
                }
            }
        }

        setState({
            is_refreshing: false,
            pending_count: 0,
            last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a"),
            dsrs: newDsrs
        })
    }

    @Action(DsrActions.AddOne)
    addOne({getState, patchState}: StateContext<DsrStateModel>, { payload }:DsrActions.AddOne) {
        const state = getState()              
        patchState({
            dsrs: [...state.dsrs, payload]
        })
    }

    @Action(DsrActions.UpdateOne)
    updateOne({setState}: StateContext<DsrStateModel>, { payload }:DsrActions.UpdateOne) {
        setState(
            patch<DsrStateModel>
                ({
                    dsrs: updateItem((item: Dsr) => item.id === payload.id, 
                        patch<Dsr>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(DsrActions.DeleteOne)
    deleteOne({setState}: StateContext<DsrStateModel>, { payload }:DsrActions.DeleteOne) {
        setState(
            patch<DsrStateModel>
                ({
                    dsrs: updateItem((item: Dsr) => item.id === payload.id, 
                        patch<Dsr>
                            (payload)
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(DsrActions.ApiAddedOne)
    apiAddedOne({ setState }: StateContext<DsrStateModel>, { payload }:DsrActions.ApiAddedOne) {         
        setState(
            patch<DsrStateModel>
                ({
                    dsrs: updateItem((item: Dsr) => item.uuid === payload.uuid, 
                        patch<Dsr>
                            ({
                                id: payload.id,
                                is_pending_save: false,
                                dsr_status_id: payload.dsr_status_id,
                                rejected_reason: payload.rejected_reason,
                                service_entries: payload.service_entries
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(DsrActions.ApiUpdatedOne)
    apiUpdatedOne({ setState }: StateContext<DsrStateModel>, { payload }:DsrActions.ApiUpdatedOne) {         
        setState(
            patch<DsrStateModel>
                ({
                    dsrs: updateItem((item: Dsr) => item.id === payload.id, 
                        patch<Dsr>
                            ({
                                is_pending_save: false,
                                dsr_status_id: payload.dsr_status_id,
                                rejected_reason: payload.rejected_reason,
                                service_entries: payload.service_entries
                            })
                        ),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(DsrActions.ApiDeletedOne)
    apiDeletedOne({ setState }: StateContext<DsrStateModel>, { payload }:DsrActions.ApiDeletedOne) {         
        setState(
            patch<DsrStateModel>
                ({
                    dsrs: removeItem((item: Dsr) => item.id === payload.id),
                    last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                })                
        )
    }

    @Action(DsrActions.UpdateServiceEntriesApproval)
    updateServiceEntriesApproval({ setState, getState }: StateContext<DsrStateModel>, { payload }:DsrActions.UpdateServiceEntriesApproval) { 
        
        // Update a copy of service entries
        const state = getState()
        const dsr = state.dsrs.find(f => f.uuid === payload.uuid)
        const patches = { is_approval_pending: true, time_approval_status_id: 1, employee_approval_date: DateTime.utc().toISO() as string };
        const updateItems = dsr?.service_entries.map((r, i) => updateItem<ServiceEntry>(i, patch(patches)));
        
        if (updateItems) {
            // Patch in the new copy of service entries, with updates necessary
            setState(
                patch<DsrStateModel>
                    ({
                        dsrs: updateItem((item: Dsr) => item.uuid === payload.uuid, 
                            patch<Dsr>
                                ({
                                    service_entries: compose(...updateItems),
                                })
                            )                    
                    })                
            )
        }
    }

    @Action(DsrActions.ApiUpdatedServiceEntriesApproval)
    apiUpdatedServiceEntriesApproval({ setState, getState }: StateContext<DsrStateModel>, { payload }:DsrActions.ApiUpdatedServiceEntriesApproval) {         
        // Update a copy of service entries
        const state = getState()
        const dsr = state.dsrs.find(f => f.uuid === payload.uuid)
        const patches = { is_approval_pending: false };
        const updateItems = dsr?.service_entries.map((r, i) => updateItem<ServiceEntry>(i, patch(patches)));

        if (updateItems) {
            setState(
                patch<DsrStateModel>
                    ({
                        dsrs: updateItem((item: Dsr) => item.uuid === payload.uuid, 
                            patch<Dsr>
                                ({
                                    service_entries: compose(...updateItems),
                                })
                            ),
                        last_update: DateTime.now().toFormat("M/d/yyyy, h:mm a")                 
                    })                
            )
        }
    }
}

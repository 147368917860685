<ion-app>
  <ion-menu contentId="main-content" side="start" type="overlay" swipeGesture="false">

    <ion-content>
      <ion-list>
        
        <ion-menu-toggle auto-hide="false">

          <ion-item button routerDirection="root" lines="none" detail="false" (click)="closeMenu()" style="cursor: pointer;">
            <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
            <ion-label>Back</ion-label>
          </ion-item>

        </ion-menu-toggle>

        <ion-item [routerLink]="" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
          <ion-label>Home (future)</ion-label>
        </ion-item>

        <ion-menu-toggle auto-hide="false">

          <ion-item [routerLink]="['tabs/wor']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>Work Order Request</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/jsa']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>JSA</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/dsr']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>DSR</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/forms']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>Forms</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/mrf']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>MRF</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/schedule', DateTime.now().toFormat('M/d/yyyy')]" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>Schedule</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/time-sheet']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>Timesheet</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/expenses']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>Expenses</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/pto-page']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>Sick/Vacation</ion-label>
          </ion-item>

          <ion-item [routerLink]="['tabs/support']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>Support</ion-label>
          </ion-item>

        </ion-menu-toggle>
          
          <ion-item [routerLink]="" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>Help/Training (future)</ion-label>
          </ion-item>

        <ion-menu-toggle auto-hide="false">

          <ion-item [routerLink]="['tabs/about']" routerDirection="root" lines="none" detail="false" routerLinkActive="active-menu-item">
            <ion-label>About</ion-label>
          </ion-item>

        </ion-menu-toggle>

        <!-- <p class="menu-separator">&nbsp;</p> -->

      </ion-list>
    </ion-content>

  </ion-menu>

  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
import { State, Action, StateContext, Selector, Store, NgxsOnInit } from '@ngxs/store'
import { User } from '../models/user.model'
import { UserActions } from '../actions/user.actions'
import { Injectable } from '@angular/core'
import { LoggerService } from 'src/app/services/logger.service'

export class UserStateModel {
    user: User
    static getDefaultUser(): User {
        return {
            id: 0, 
            name: '',
            email: '', 
            token: '', 
            is_logged_in: false, 
            manager: '',
            company_id: 0,
            area_id: 0
        }
    }
}

@State<UserStateModel>({
    name: 'user',
    defaults: {
        user: UserStateModel.getDefaultUser()
    }
})
@Injectable()
export class UserState implements NgxsOnInit {

    async ngxsOnInit(ctx: StateContext<UserStateModel>) {    
        const state = ctx.getState()            
        const user: User = state.user
        LoggerService.setUser(user)
    }

    @Selector()
    static getUser(state: UserStateModel) {
        return state.user
    }

    @Action(UserActions.Login)
    login({getState, setState}: StateContext<UserStateModel>, { payload }:UserActions.Login) {
        const state = getState()
        const newState = {user: {...state.user}}
        newState.user.id = payload.id
        newState.user.name = payload.name
        newState.user.email = payload.email
        newState.user.manager = payload.manager
        newState.user.is_logged_in = true
        newState.user.company_id = payload.company_id
        newState.user.area_id = payload.area_id
        setState(newState)
    }

    @Action(UserActions.Logout)
    reset({setState}: StateContext<UserStateModel>, { }:UserActions.Logout) {
        setState({user: UserStateModel.getDefaultUser()})
    }

    @Action(UserActions.SetToken)
    set_user_token({getState, setState}: StateContext<UserStateModel>, { payload }:UserActions.SetToken) {              
        const state = getState()
        const newState = {user: {...state.user}}
        newState.user.token = payload.token
        setState(newState)
    }

}

import { Injectable } from '@angular/core'
import { Select, State, Action, StateContext, Selector, NgxsOnInit, Store, NgxsSimpleChange } from '@ngxs/store'
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state-man/state/user.state';
import { User } from 'src/app/state-man/models/user.model';
import { CategoryActions } from '../actions/category.actions';
import { Category } from '../models/category.model';
import { CategoryService } from 'src/app/services/category.service';
import { StorageService } from 'src/app/services/storage.service';

export class CategoryStateModel {
    is_refreshing: boolean
    category: Category[]
}

@State<CategoryStateModel>({
    name: 'category',
    defaults: {
        is_refreshing: true,
        category: []
    }
})
@Injectable()
export class CategoryState implements NgxsOnInit {
    @Select(UserState.getUser) user$: Observable<User>

    constructor(        
        private categoryService: CategoryService,
        private store: Store,
        private storageService: StorageService
    ) {}

    async ngxsOnInit(ctx: StateContext<CategoryStateModel>) {
        this.user$.subscribe(async (user: User) => {            
            if (user.is_logged_in) {     
                this.storageService.events.subscribe(async (status: any) => {
                    if (this.storageService.is_initialized) {                        
                        await this.reloadStateFromStorage()                        
                    }
                })                           
            }
        }) 
    }

    async ngxsOnChanges(change: NgxsSimpleChange) {           
        if (this.storageService.is_initialized) {   
            const state_model: CategoryStateModel = change.currentValue
            this.storageService.set('categories', JSON.stringify(state_model))
        }    
    }

    async reloadStateFromStorage() {        
        const json: string = await this.storageService.get('categories');
        if (json) {
            const state_model: CategoryStateModel = JSON.parse(json)
            if (state_model) {                
                this.store.dispatch(new CategoryActions.Init(state_model));
                return
            } 
        }
        this.store.dispatch(new CategoryActions.Refresh())
    }

    async reloadStateFromApi() : Promise<boolean> {    
        const objs = await this.categoryService.getAll()
        if (objs != null) {
          const many = Category.buildMany(objs)
          this.store.dispatch(new CategoryActions.Set(many))
          return true
        }
        return false
    }
  
    @Selector()
    static getState(state: CategoryStateModel) {
        return state
    }

    @Selector()
    static getIsRefreshing(state: CategoryStateModel) {
        return state.is_refreshing
    }

    @Selector()
    static getAll(state: CategoryStateModel) {
        return state.category
    }

    @Action(CategoryActions.Refresh)
    async refresh({patchState}: StateContext<CategoryStateModel>, {  }:CategoryActions.Refresh) {
        patchState({is_refreshing: true})
        if (!await this.reloadStateFromApi()) {            
            patchState({is_refreshing: false})
        }
    }
    
    @Action(CategoryActions.Init)
    init({setState, getState}: StateContext<CategoryStateModel>, { payload }:CategoryActions.Init) {
        const state = getState()
        payload.is_refreshing = state.is_refreshing
        setState(payload)
        this.store.dispatch(new CategoryActions.Refresh())
    }

    @Action(CategoryActions.Set)
    set({setState}: StateContext<CategoryStateModel>, { payload }:CategoryActions.Set) {
        setState({    
            is_refreshing: false,        
            category: payload
        })
    }
}
